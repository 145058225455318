<template>
    <div class="news">
        <!--头部导航-->
        <div class="news-nav">
            消息
        </div>
        <div class="row-center">
            <div class="grid group-space-between" style="width:80%">
                <div class="gridItem"  @click="btnComment()">
                    <div > <img src="../../assets/img/comment.png" style="width:54px;margin: 6px 0 6px 0;"/></div>
                    <div> 评论</div>
                </div>
                
                <div class="gridItem"   @click="btnCollectAndLike()">
                    <div > <img src="../../assets/img/zan.png" style="width:54px;margin: 6px 0 6px 0;"/></div>
                    <div> 赞与收藏</div>
                </div>
                
                <div class="gridItem"  @click="btnFocus()">
                    <div > <img src="../../assets/img/follow.png" style="width:54px;margin: 6px 0 6px 0;"/></div>
                    <div> 新增关注</div>
                </div>
            </div>
        </div>
        <div class="news-list-item" @click="btnseeMessage()">
            <div class="item-img">
                <img src="../../assets/img/message.png"/>
            </div>

            <div class="item-name">
                <div class="group-space-between" style="margin-bottom:5px;">
                    消息通知  <!--时间-->
                    <span v-if="unreadMessageCount!=0" class="number_top_circle">{{unreadMessageCount}}</span>
                    <span v-if="unreadMessageCount==0" >⋯</span>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
    
    import {getMessageCount} from "network/new"
    export default {
        name: "News",
        components:{
        },data(){
            return{
                user_id:null,
                newslist:[],
                unreadMessageCount:0,
            }
        },created() {
            this.user_id=window.sessionStorage.getItem("userId")
            this.getUnreadMessageCount()
            // this.getNews()
        },methods:{
            getUnreadMessageCount(){                
                    var that = this;
                    getMessageCount(0).then(res=>{
                        that.unreadMessageCount = res.data.result.count
                    })
                },
             /*查看系统消息*/
            btnComment() {
                this.$router.push({
                    name: 'Chat'
                })
            },
           btnCollectAndLike() {
                this.$router.push({
                    name: 'CollectAndLike'
                })
            },
            /*查看粉丝*/
            btnFocus(){
                this.$router.push({
                    name: 'personalfans'
                })
            },
            /*查看系统消息*/
            btnseeMessage() {
                this.$router.push({
                    name: 'MessageList'
                })
            },
            getNews(){
                // getNews().then(res=>{
                //     let x=0
                //     this.newslist=res.data.newsList
                //     for(let i=0;i<this.newslist.length;i++){
                //         if(this.newslist[i].senuser_id==this.user_id){
                //             for(let j=0;j<this.list.length;j++){
                //                 if(this.newslist[i].recuser_id==this.list[j]){
                //                     x++
                //                 }

                //             }
                //             if(x==0){
                //                 this.list.push(this.newslist[i].recuser_id)
                //             }
                //             x=0

                //         }else if(this.newslist[i].recuser_id==this.user_id){

                //             for(let j=0;j<this.list.length;j++){
                //                 if(this.newslist[i].senuser_id==this.list[j]){
                //                     x++
                //                 }
                //             }
                //             if(x==0){
                //                 this.list.push(this.newslist[i].senuser_id)
                //             }
                //             x=0
                //         }
                //     }
                // })
            },
           


        },mounted() {


        }
    }
</script>

<style scoped>
    .news-list-item{
        display: flex;
        padding: 10px 10px;
    }
    .item-img img{
        width:54px;
        border-radius: 50%;
        margin-right: 10px;
    }
    .item-name{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid rgba(180,180,180,.3);
    }
    .item-name span{
        font-size: 14px;
    }
    .item-name a{
        font-size: 12px;
        margin-top: 5px;
    }
    .news-nav{
        height: 39px;
        line-height: 39px;
        text-align: center;
    }
    .grid {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}
	
	.gridItem {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 33rpx;
	}
	
	.gridImg {
		width: 140rpx;
		height: 140rpx;
	}
    .group-space-between{
		display: flex;
		flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 10rpx 10rpx;
	}
    .number_top_circle {
        display: table-cell;
        border-radius: 18px;
        background-color: #F00;
        width: 16px;
        height: 16px;
        text-align: center;
        vertical-align: middle;
        color: #fff;
    }
    .row-center{
        display: flex;
		flex-direction: row;
		justify-content:center;
        margin: 20px 0 20px 0;font-size: 14px;
    }
</style>